h4 {
  font-size: 3rem !important;
  color: #525460
 !important;
}
h5 {
  font-size: 1.5rem !important;
  color: #525460
  !important;
}
p {
  font-size: 1rem !important;
  color: rgb(74, 74, 74);
}
#root {
  overflow-x: hidden !important;
}
.home-sec-2 p {
  font-size: 1rem !important;
  color: #000000;
}
.sec-2-1 img {
  height: 65% ;
  width: 100% ;
}
span {
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important;
}
.section-5 h4,
.section-5 p {
  color: #ffffff !important;
}
.section-5 p b {
  font-size: 19.2px !important;
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important;
}
.section-6 p {
  color: #ffffff !important;
}
.section-9 h4 {
  color: #ffffff !important;
}
.tab-panel p {
  color: #ffffff !important;
  font-size: 0.9rem !important;
  margin-bottom: 30px;
}
.section-11 h4 {
  color: #ffffff !important;
}
.section-11 h6 {
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important;
  font-size: 1.2rem !important;
}
.section-11 p {
  color: #ffffff !important;
  font-size: 1rem !important;
}
.section-12 p {
  color: #ffffff !important;
  line-height: 1rem !important;
  font-size: 1rem !important;
}
.section-13 h4 {
  color: #ffffff !important;
}
.section-13 p {
  color: #ffffff !important;
}
.section-13 .slider-box p {
  color: #525460
 !important;
}

/* Header */

.offcanvas {
  width: 250px !important;
}

.header-routes-canvas a {
  list-style: none;
  padding: 20px 10px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
}
.header-routes-canvas a {
  text-decoration: none;
}
.header-routes a {
  text-decoration: none;
  color: black;
}

.header-routes {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}
.header-routes a {
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  height: 70px;
  display: flex;
  padding: 0 30px;
  /* border-radius: 20px; */
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

/* home section */

.home-sec-1 {
  height: calc(100vh - 70px);
}
.home-sec-1 .home-img img {
  height: calc(100vh - 70px);
  width: 100%;
}

/* section 2 */

.section-2 {
  flex-direction: column;
}

.home-sec-2 {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home_boxes .sec-2-1 {
    height: 350px;
    display: block;
    margin: -90px 10px 80px !important;
    border-radius: 0;
    width: 300px;
    padding: 15px;
    border: 5px solid #fff;
    box-shadow: 1px 2px 20px #eee;
}
.home_boxes h5 {
    margin-top: 15px;
    font-size: 20px !important;
    color: #525460;
}
.home_boxes p {
    font-size: 13px !important;
    color: #000000;
}

/* section 3  */

.section-3 {
  width: 100%;
}
.section-3 img {
  width: 100%;
}

/* Section 4 */
.section-4 {
  flex-direction: column;
}

/* .section-4  */

thead,
.thead tr {
  height: 80px !important;
  border-style: none !important;
}

.table > thead {
  color: white !important;
  background-color: #525460
 !important;
}
.table > thead > tr > th {
  background-color: #525460
 !important;
  vertical-align: middle !important;
  color: white !important;
  border-style: none !important;
  text-align: center !important;
  font-size: 1.2rem;
}
.table > tbody > tr > td {
  vertical-align: middle !important;
  border-style: none !important;
  text-align: center !important;
}
.table > thead > tr {
  height: 100px !important;
  border-radius: 10px !important;
}
.table > tbody > tr {
  height: 100px !important;
  border-radius: 10px !important;
}
.get-price-btn {
  background-color: #525460;
  color: #ffffff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  width: max-content;
  border-radius: 1.6px;
  
  margin-top: 25%;
  margin-left: auto;
  margin-right: auto;
}

/* section 5 */
.section-5 {
  background: #525460
;
  color: #ffffff;
}
/* Section 6 */

.floor-plans {
  background: #525460
;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-direction: column;
  border-radius: 0 5px 5px 0;
}

.text-align-justify {
  text-align: justify !important;
}
/* Section 8*/
.img-gallery  div{
  /* background: #e8c551 !important; */
}
.img-gallery img {
  width: 100%;
}


/* Section 9*/

.section-9 {
  background: #525460
;
}
.floor-plans-poster {
  display: flex;
  justify-content: center;
  align-items: center;
}
.floor-plans-poster img {
  width: 100%;
}

.floor-plans h4 {
background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent;
}

.btn-yellow.living {
  position: absolute;
  display: flex;
  align-self: center;
  right: 40px;
}

/* .Section 10 */

.img-sec img {
  width: 40px;
}
.img-section {
  display: flex;
  flex-wrap: wrap;
}

.tab-panel .MuiBox-root {
  background-color: #525460
;
  border-radius: 8px;
  color: #ffffff;
}
.MuiTabs-flexContainer button {
  border-bottom: 1px solid white !important;
  padding: 25px !important;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important;
}
.css-1q2h7u5 {
  align-items: start !important;
}

.css-10d9dml-MuiTabs-indicator {
  border-radius: 5px 0 0 5px;
  width: 5px !important;
  background-color: #ffffff !important;
}

.css-1q2h7u5.Mui-selected {
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1q2h7u5 {
  background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%) !important; -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent;
}
.MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-j7qwjs {
  margin-top: 1rem !important;
}
.MuiTabs-indicator.css-7ozjwe {
  border-radius: 5px 0 0 5px;
  width: 5px !important;
  background-color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #ffffff !important;
  align-items: start !important;
  webkit-align-items: start !important;
}

.MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
  margin-top: 2rem;
}

.MuiBox-root.css-19kzrtu {
  margin-top: 2rem;
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%);
  padding: 0 25px;
}

/* Section 11 */

.section-11 {
  background: #525460
;
  color: #ffffff;
}

.section-11 img {
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}
/* Section 13 */

.section-13 {
  background: #525460
;
  color: #ffffff;
}

/* Section 14 */

.accordion-item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 8px !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #525460
 !important;
 box-shadow :none !important
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  background: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  padding: 5px !important;
  border-radius: 50%;
  background-size: 70% 70% !important;
  background-position: center !important;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 8px !important;
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
  background: #525460
 !important;
  /* color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important; */
  font-weight: 700 !important;
  border-radius: 0 !important;
  /* border-bottom: 1px solid white !important; */
}
.accordion-collapse.show {
  background: #525460
 !important;
  color: #ffffff !important;
}
/* Sliders */

.card-box-1 {
  padding: 0 5rem;
}
.slick-initialized .slick-slide1 {
    display: block;
    margin: 5px;
    border-radius: 10px;
}
.card-box {
    background-color: #ffffff;
    color: #525460;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 10px 10px 20px #93939333;
    text-align: center;
    min-height: 310px;
}
.card-box p {
    font-size: 14px !important;
}
.card-box svg path {
    color: #ffc800;
}
.btn-green {
  border-radius: 18px;
  background: #525460
;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 550;
  width: max-content;
  color: #ffffff;
  padding: 15px 30px;
  box-shadow: 0px 10px -9px 0px rgba(192, 192, 192, 0.35);
}
.btn-yellow {
  border-radius: 18px;
  background: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%);
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 550;
  width: max-content;
  color: #000000;
  padding: 15px 30px;
  box-shadow: 0px 10px -9px 0px rgba(192, 192, 192, 0.35);
}
.confirm-btn {
  border-radius: 18px;
  background: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%);
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 550;
  width: max-content;
  color: #000000;
  padding: 15px 30px;
  box-shadow: 0px 10px -9px 0px rgba(192, 192, 192, 0.35);
}

.btn-yellow.tabs {
  padding: 10px 40px;
}

/* footer */

.footer {
  background: #525460;
  color: #ffffff;
  z-index: 1;
}

.footer p {
  color: #ffffff !important;
  font-size: 1rem !important;
}
.footer svg {
  color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%);
  font-size: 1.5rem;
}
.footer h6 {
  /* color: linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important; */
  font-size: 1.2rem !important;
}
.footer input {
  background: #ffffff;
  border: none;
  height: 50px;
  color: #525460
 !important;
}
.footer input:focus {
  background: #ffffff !important;
  border: none;
  box-shadow: none !important;
  /* border: 2px solid linear-gradient(180deg, #F1DB71 0%, #D9A624 100%) !important; */
  height: 50px;
}
.footer .btn-yellow {
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 1.2rem;
  margin-top: 20px;
}
.slider-box .card-box {
  height: 300px;
}
.footer .form-control {
  border-radius: 2px !important;
}

.home-sec-1 .slick-next {
  right: 30px !important;
  /* top: calc(100vh - 110px) !important; */
  /* border: 2px solid #ebf9f7ba !important; */
  width: 40px !important;
  height: 40px !important;
  /* border-radius: 4px; */
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}
.home-sec-1 .slick-prev {
  left: 30px !important;
  /* top: calc(100vh - 110px) !important; */
  /* border: 2px solid #ebf9f7ba !important; */
  width: 40px !important;
  height: 40px !important;
  /* border-radius: 4px; */
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
}

.slick-prev:before {
content: none !important;
}

.slick-next:before {
content: none !important;

}

.modal-title {
  display: flex;
  justify-content: center;
  margin: auto;
}
button.btn-close {
  position: absolute;
  right: 30px;
}
.modal-form input {
  height: 50px;
  border: 2px solid #525460
;
  border-radius: 5px;
  color: #525460
;
}
.phone-input input {
  height: 50px !important;
  border: 2px solid #525460
 !important;
  border-radius: 5px !important;
  color: #525460
 !important;
  width: 100% !important;
}
.modal-form select {
  height: 50px;
  border: 2px solid #525460
;
  border-radius: 5px;
  color: #525460
;
}
.form-control:focus {
  box-shadow: none !important;
}

.flag-dropdown {
  border: 2px solid #525460
 !important;
}
.confirm-page-container {
  background: #fff8dc;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* 
p.text-yellow {
  background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%) !important;
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent; 
  background-clip: text; 
  text-fill-color: transparent;;;
}
.text-yellow {
  background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%) !important;
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
    background-clip: text; 
    text-fill-color: transparent;;
} */
.logo {
  height: 100%;
  width: 65% !important;
}
.logo2 {
  height: 100%;
  width: 35% !important;
  
}

/* section 13 */

.section-builder {
  background: #ffffff;
}

.section-builder h5 {
  color: #ffffff !important;
}
.section-builder h6 span {
  background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%) !; -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent;
}
.section-builder .musings img {
  width: 100%;
}

.section-builder .box {
  border: 1px solid linear-gradient(180deg, #F1DB71 0%, #D9A624 100%);
  background: #525460
;
  border-radius: 5px;
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  padding: 5px;
}
.section-builder .box p {
  color: #ffffff !important;
  text-align: center;
  font-size: 0.8rem !important;
}

.redirect-img{
  position: absolute;
  bottom: 50px;
  left: 50px;
}
.redirect-img img{
  height: 80px;
}

#slider_testimonial {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
/* responsive */
@media (max-width: 1320px) {
  .slider-box .card-box {
    height: 300px;
  }
  .home_boxes h5{
    font-size: 18px !important;
  }
  .home_boxes p {
    font-size: 14px !important;
  }
  .sec-2-1 img {
    height: 40% !important;
    width: 100% !important;
  }
  #slider_testimonial {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media (max-width: 1024px) {
  .home-sec-2 .sec-2-1 {
    width: 230px;
    height: 290px;
    padding: 0 5px;
  }
  h4 {
    font-size: 2.3rem !important;
  }
  h5 {
    font-size: 1.2rem !important;
  }
  p {
    font-size: 0.9rem !important;
  }
  .btn-yellow.living {
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 1rem;
  }
  .css-1k84tew {
    height: 580px !important;
  }
 
  .home_boxes h5{
    font-size: 20px !important;
  }
  .home_boxes p {
    font-size: 14px !important;
  }
  .sec-2-1 img {
    height: 50% !important;
    width: 100% !important;
  }
  #slider_testimonial {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media (max-width: 768px) {
  .logo {
    width: 100% !important;
  }
  .logo2{
    width: 90% !important;
  }
  
  .bg-dark-md {
    background: #525460
;
  }
  .btn-yellow.living {
    font-size: 1rem;
  }
  .section-11 img {
    height: 30px;
  }
  .css-1k84tew {
    height: max-content !important;
  }
  .redirect-img{
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .redirect-img img{
    height: 50px;
  }
  .home-sec-2 .sec-2-1{
    height:  260px;
    width: 200px;

  }
  .home_boxes h5{
    font-size: 16px !important;
  }
  .home_boxes p {
    font-size: 10px !important;
  }
  .sec-2-1 img {
    height: 60% !important;
    width: 100% !important;
  }
  #slider_testimonial {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (max-width: 568px) {
  .logo {
    width: 100% !important;
  }
  .logo2 {
    width: 90% !important;
  }
  h4 {
    font-size: 1.5rem !important;
  }
  h5 {
    font-size: 1rem !important;
  }
  p {
    font-size: 0.8rem !important;
  }
  .home-sec-2 .sec-2-1{
    height:  260px;
    width: 140px;

  }
  .home_boxes h5{
    font-size: 15px !important;
  }
  .home_boxes p {
    font-size: 10px !important;
  }
  .sec-2-1 img {
    height: 50% !important;
    width: 100% !important;
  }
  #slider_testimonial {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (max-width: 455px) {
  .logo {
    width: 100% !important;
  }
  .logo2 {
    width: 90% !important;
  }
  .card-box {
    padding: 2rem;
  }
  .card-box-1 {
    padding: 0 1rem;
  }
  .home-sec-2 .sec-2-1{
    height:  200px;
    width: 100px;

  }
  .home_boxes h5{
    font-size: 12px !important;
  }
  .home_boxes p {
    font-size: 8px !important;
  }
  .sec-2-1 img {
    height: 40% !important;
    width: 100% !important;
  }
  #slider_testimonial {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.hr-footer-color {
  color: rgba(215, 162, 48, 1);
  border-width: 3px;
}

.yellow-title{
  background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%);
   -webkit-background-clip: text; 
   -webkit-text-fill-color: transparent; 
   background-clip: text; 
  text-fill-color: transparent;
font-size: 24px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
}

.btn-outline-warning {
  border-radius : 2px !important;
    background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%);
     -webkit-background-clip: text; 
     -webkit-text-fill-color: transparent; 
     background-clip: text; 
    text-fill-color: transparent;
    font-weight: 700 !important;
}

.yellow-underline{
 text-decoration: underline;
 text-decoration-color: #D7A230;
 font-weight: 400 !important;
}
.building-img{
  height: 1.51%;
    width: 107.71% !important;
    top: 93.21% !important;
    right: -3.82% !important;
    bottom: 5.28% !important;
    left: -3.89%;
    /* max-width: 100%; */
    overflow: hidden;
    /* max-height: 100%; */

}

.accordion .accordion-item .accordion-button:not(.collapsed) h6 {
  background: linear-gradient(180deg, #D7A230 0%, #F4DE73 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent;
font-size: 24px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
}

.accordion-item {
  border-color: #525460 !important;
  border-radius : 0px !important
}

.slick-slide   {
  background-color: #fff;
}

#root {
  background-color: #eae9e8;
}


.premia_text {
    position: relative;
    overflow: hidden;
}
.premia_text h3 {
    font-size: 158px;
    position: absolute;
    /* top: 22%; */
    bottom: -30px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    color: #525460;
}
.premia_text .building-img {
    position: relative;
    top: 50px !important;
    left: 0;
}



.slick-list {
  padding: 0px !important;
}
.slick-prev {
  left: -4% !important;
  z-index: 1;
}
.slick-next {
  right: -4% !important;
  z-index: 1;
}




#location_down .accordion-button::after {
    display: none;
}
#location_down .accordion.accordion-flush .accordion-item {
    text-align: center;
    padding: 20px;
}

#location_down button.accordion-button {
    text-align: center;
    display: block;
}
#location_down button.accordion-button h6.mb-0 {
    font-size: 20px;
}

#location_down .accordion-button:not(.collapsed) {
    box-shadow: none;
}

#location_down .accordion-item {
    border: none;
}

#slider_testimonial .card-box {
    background-color: #ffffff;
    color: #525460;
    padding: 25px;
    border-radius: 20px;
    box-shadow: 10px 10px 20px #93939333;
    text-align: center;
    min-height: 350px;
}

#slider_testimonial .slide-card {  
    border-radius: 20px;
    
}

#slider_testimonial .slick-initialized .slick-slide {
    border-radius: 10px;
}


#slider_testimonial .slick-slider.slick-initialized {
  background: #eae9e8;
  padding: 30px;
}

.sec_title  {
  font-weight: 600;
  margin-bottom: 5%;
}

#slider_testimonial .slick-track  {
  width: 2600px !important;
}

.card {
  justify-content: center;
  align-items: center;
  padding: 10%;
}
.card_h5 {
  padding : 5px;
  font-weight: 700;
}

.card-grid{
  border-right: 3px solid #e8c551;
  border-bottom: 3px solid #e8c551;
}
@media only screen and (min-width:900px){
  .card-grid:nth-child(3n){
    border-right: 3px solid transparent !important;
  }
  .card-grid:nth-last-child(-n+3), .card-grid:nth-last-child(-n+3) ~ .card-grid {
      border-bottom: 3px solid transparent !important;
  }
}

@media only screen and (max-width:600px) {
  .card-grid{
    border-right:0 !important;
  }
  
}
.btn-rotate{
  transform: rotate(90deg);
}



.container2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
 
  
}

.box2 {
  background-color:  #EAE9E8;

  color:  #525460;
  
  padding: 20px;
  margin: 10px;
  text-align: center;
  width: 22%;
  
}
@media (max-width: 768px) {
  .box2 {
    flex: 1 0 60%; /* Take up full width in a column */
  }
}

.text2 {
  margin-bottom: 10px; 
}
.text2-co {
  margin-bottom: 10px; 
  font-weight: 500;
  text-align: center;
  font-size: large;
}
.text2-sz {
  margin-top: 40px; 
  font-weight: 500;
  text-align: center;
  font-size: large;
}
.text2-bhk {
  margin-bottom: 10px; 
  font-weight: 600;
  text-align: center;
  font-size: xx-large;
}
.text2-sq {
  margin-bottom: 10px; 
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
  font-size: larger;
}

/* .arrow-btn {
  cursor: pointer;
  border: none;
  background: transparent;
  
  padding: 0;
  float: right;
  outline: none;
  width: 0;
  color:  #525460;

} */
.arrow-btn {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 20px;
  padding: 0;
  outline: none;
  color:#525460;
  position: absolute;
  top: 1;
  right: 0;
  margin: 15px; /* Adjust margin as needed */
}
.button-container {
  display: none; /* Initially hide the buttons */
}

.right-middle-button {
  position: fixed;
  top: 50%;
 display: none;
 right: -80px;
  transform: rotate(270deg);
  text-align: center;
 
}

.right-middle-button button {
  font-size: 14px;
  background-color:  #525460;
  color:  #E5D4C7;
  font-size: 14px;
  padding: 8px 16px;
  width: 210px;
}

.icon-b {
  margin-left: 5%;
}

@media only screen and (max-width: 600px) {
  .button-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
   
    background-color: #f0f0f0;
  }

  .button-container button {
    width: 50%; 
    height: 45px;
    font-size: 16px;
    /* Equal width for both buttons */
  }

  .call-now {
    background:#525460;
    color:  #E5D4C7;
    ;

  }

  .whatsapp {
    background: #29A71A;
    color:  #FFFFFF;
    ;
  }
  .icon-but {
    margin-right: 4%;
  }
  .right-middle-button{
    display: block;
  }
 
}

