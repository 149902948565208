@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


#slider_testimonial .slick-slider .slick-prev::before{
    content: unset !important;
    display: none !important;
}
#slider_testimonial .slick-slider .slick-next::before{
    content: unset !important;
    display: none !important;
}

/* .img-gallery img {
    border: 3px solid transparent; 
} */

table {
    border-color: #212529 !important;
    border: 1px solid #212529;
    font-family: 'Darker Grotesque', sans-serif !important;
}

th,
td {
    border-color: #212529 !important;
    border: 1px solid #212529 !important;
}

.table>tbody>tr>td {
    border-color: #212529 !important;
    border: 1px solid #212529 !important;
    background-color:rgba(234, 233, 232, 1);
}

.table>tbody>tr>td p {
    font-size: 25px !important;
    font-weight: 600 !important;
}


.gallery_border {
    border-right: 3px solid #e8c754 !important;
    border-left: 3px solid #e8c754 !important;
}

.gallery_border_middle {
    border-top: 3px solid #e8c754 !important;
    border-bottom: 3px solid #e8c754 !important;
}
.section_head {
    /* height: 400px; */
    padding: 3% 10% 10%;
    text-align: center;
    text-transform: capitalize;
}
.choose_section_head {
    padding: 0% 0% 10%;
    text-align: center;
    text-transform: capitalize;
}


#faqs{
    font-family: 'Darker Grotesque', sans-serif !important;
}
.fq_font{
    font-size: 22px;
    color:  rgba(82, 84, 96, 1) !important;
    gap: 10px;
    font-weight: 600;
    justify-content: center;
}

.card_main {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}


.card_detail {
    padding: 15px;
    width: 200px;
    /* height: 280px; */
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    text-align: left;
    border-radius: 5px;
}

.card_detail img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}



/* YouTubeVideo.css */

.youtube-video-container {
    margin: 20px;
}

.youtube-video-container iframe {
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.card_detail h4 {
    font-size: 26px !important;

}

.card_detail p {
    font-size: 15px !important;
}

/* section8 */

/* #amenities {
 padding-bottom: 8% !important;
 padding-top: 8% !important;
} */

.amenities_title {
    font-family: 'Darker Grotesque', sans-serif !important;
    font-weight: 800;
    margin-bottom: 5%;
}

.amenities_main {
    padding: 5% 10%;
    text-align: center;
    text-transform: capitalize;
    background-color: #EAE9E8;
}

.travel_main {
    text-align: center;
    text-transform: capitalize;
    background-color: #EAE9E8;
}

.amenities_card_main {
    display: flex;
    flex-wrap: wrap;
    gap:17px;
    width: 75%;
    justify-content: center;
    margin: auto;
}
.amenities_card_detail {
    position: relative;
    overflow: hidden;
  }
  .relative-pos{
    position: relative;
    overflow: hidden;
  }

  .additional_details {
    position: absolute;
    top: 0;
    /* Initially off-screen to the right */
   /* Take up the full width of the parent */
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
  }
  
  .amenities_card_detail:hover .additional_details {
    right: 0; /* Move it into view on hover */
  }
  
  @media (max-width: 768px) {
    .amenities_card_main {
      width: 100%;
    }
  
    .additional_details {
      right: 0; /* Adjust the position for smaller screens */
    }
  }
  
  

  
.travel_card_main {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 75%;
    justify-content: center;
    margin: auto;
}

.amenities_card_detail {
    width: 200px;
    height: 170px;
    /* padding: 3%; */
    border-radius: 5px;
    background-color: #fff;
    font-family: 'Darker Grotesque', sans-serif !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}

.amenities_card_detail img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
}

.amenities_card_detail h4 {
    font-size: 22px !important;

}

.btn_explore {

    height: 50px;
    border-radius: 2px;
    padding: 5px 40px;
    background-color: #525460;
    border: 1px solid#525460;
    color: #fff;
    margin: 40px 0px 100px;
    font-family: 'Darker Grotesque', sans-serif !important;
    font-size: 25px;
}

.btn_explore:hover {
    background-color: #525460 !important;
    color: #fff !important;
}


/* demo css */
.demo_main {
    font-family: 'Darker Grotesque', sans-serif !important;
    background-color: #eae9e8;
    color: #525460 !important;
}

.demo_content {
    padding: 60px 0px;
}

.content_first {
    display: flex;
    align-items: center;
}

.left-panel {
    flex: 0 0 50%;
    padding: 20px;
    text-align: left;
}

.para {
    padding-right: 100px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24.41px;
}

.right-panel {
    flex: 0 0 50%;
    /* padding: 20px; */
    width: 100%;
}

.heads h2 {
    font-size: 84px !important;
    font-weight: 500;
}

.know_btn {

    background-color: #525460;
    color: white;
    padding: 15px 90px;
    outline: unset;
    border: 1px solid #525460;
    /* text-transform: uppercase; */
}

.first_img {
    position: absolute;
    top: 20%;
    left: 5%;
    background-color: #fff;
    padding: 3px;
    width: 339px;
}

.img_first {
    height: 409px;
    width: 100%;
}

.sec_img {
    width: 526px;
    margin-left: auto;
    margin-right: 0px;
    background-color: #fff;
    padding: 3px;
}

.img_sec {
    width: 100%;
    height: 667px;
}

.catalog {
    background-color: #fff;
    display: flex;
    padding: 5px;
    text-align: center;
    margin: 50px 25px 0px !important;

}

.cat_detail {
    border: 1px solid black;
    padding: 30px 25px;
    /* flex: 1; */
}

.sub_title {
    font-size: 15px;
    text-transform: capitalize;
    position: relative;
    top: -10px;
}



.cat_detail p {
    font-size: 30px !important;
    padding-left: 3px;
    /* text-transform: capitalize; */
    font-weight: 700;
    margin: 0;
}

.cat_detail span {
    font-size: 20px;
    font-weight: 700;
    margin-left: 3px;
    letter-spacing: 1px;
}


/* site form */

.site_main {
    text-transform: capitalize;
    background-color: #fff;
    /* height: 800px; */
}

.site_content {
    display: flex;
    gap: 20px;
    padding: 6% 0;
}

.site_left {
    flex: 1;
    padding: 5%;
}

.site_left h2 {
    font-family: 'Darker Grotesque', sans-serif !important;
    font-weight: 800;
    font-size: 50px;
    margin-bottom: 15px;
}

.site_right {
    flex: 1;
}

.site_right img {
    width: 455.87px;
    height: 600px;
    -webkit-box-shadow: 25px 24px 1px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 25px 24px 1px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 25px 24px 1px -1px rgba(0, 0, 0, 0.75);
}

.form-group {
    font-family: 'Poppins', sans-serif !important;
    margin: 5px 0;
}

.form-group label {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin: 5px 0;
}

.form-group input {
    border-radius: 2px;
    padding: 8px 10px;
    background-color: #EAE9E8;
}

input::placeholder {
    color: #525460;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

/* PhoneNumberInput.css */

/* Add your custom styles here */
.phone-input-container {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    color: #333;
}

.site_btn {
    background-color: #525460;
    color: white;
    padding: 10px 40px;
    outline: unset;
    border: 2px solid #525460;
    border-radius: 2px;
    text-transform: uppercase;
    margin-top: 15px;
}


/* Customize the input style */
.react-tel-input {
    border-radius: 2px;
    padding: 2px 0;
    background-color: #EAE9E8;
}

/* Customize the dropdown style */


.react-tel-input .form-control {
    background: transparent !important;
    border: unset !important;

}

.react-tel-input .country-list {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.react-tel-input .special-label {
    display: none !important;
}

/* Customize the selected country flag style */
.react-tel-input .selected-flag {
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Customize the selected country flag image style */
.react-tel-input .selected-flag .iti-flag {
    width: 20px;
    height: 15px;
}

/* Customize the country list item style */
.react-tel-input .country {
    padding: 8px;

    &:hover {
        background-color: #f0f0f0;
    }
}

.react-tel-input .flag-dropdown {
    background-color: unset !important;
    border: unset !important;

}

.react-tel-input .selected-flag {
    border: unset !important;
}


/* floor plan */
.floors_main {
    background-color: #fff;
}

.floors_detail {
    padding-top: 5%;
    text-align: center;
}


/* Style for the underline effect */
.nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #007bff;
    /* Set your desired underline color */
}

.nav-tabs {
    justify-content: center;
}


.nav-tabs .nav-link.active {
    font-size: 20px !important;
    border-bottom: 2px solid #525460 !important;
    color: #525460 !important;
    font-weight: 700 !important;
}

/* Style for the inactive tab color */
.nav-tabs .nav-link {
    color: rgb(165, 161, 161);
    /* Set your desired text color for inactive tabs */
    font-size: 20px !important;
    font-weight: 400 !important;
}

.nav-tabs {
    border: unset !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    color: #525460 !important;

}

/* Remove the underline for inactive tabs */
.nav-tabs .nav-item:not(.show) .nav-link {
    border: none;

}

.tab_content {
    width: 100%;
    padding: 5%;
}

.tab_content img {
    width: 100%;
}

.aminities_card {
    height: 221px !important ;
    width: 90%;
}


@media (max-width: 1400px) and (min-width:1200px) {
    .first_img {
        top: 20%;
        left: -10%;

    }
    .card_detail h4 {
        font-size: 26px !important;
    
    }
    
    .card_detail p {
        font-size: 15px !important;
    }
    .aminities_card {
        height: 221px !important ;
        width: 90%;
    }
}

@media (max-width: 1199px) and (min-width: 1024px) {
    .left-panel {
        flex: 0 0 40%;
    }

    .cat_detail span {
        font-size: 20px;
    }

    .cat_detail p {
        padding-bottom: 2px;
    }

    .right-panel {
        flex: 0 0 60%;

    }

    .sec_img {
        width: 375px;
    }

    .first_img {
        top: 15%;
        left: 10%;
        width: 300px;
    }

    .heads h2 {
        font-size: 60px !important;
        font-weight: 500;
    }

    .para {
        padding-right: 0px;
        font-size: 20px;
    }

    .know_btn {
        padding: 10px 40px;
    }

    .img_first {
        height: 400px;
    }

    .img_sec {
        height: 550px;
    }

    .site_left h2 {
        font-size: 40px;
    }

    .form-group label {
        font-size: 15px;
    }

    input::placeholder {
        font-size: 13px;
    }
    .card_detail h4 {
        font-size: 24px !important;
    
    }
    
    .card_detail p {
        font-size: 14px !important;
    }

    .premia_text h3 {
        font-size: 130px;
        /* top : 0 */
    }
    .premia_text .building-img {
        top: 35px !important;
    }

    .aminities_card {
        height: 180px !important ;
        width: 90%;
    }
    
}

@media (max-width: 1023px) and (min-width: 769px) {
    .site_left h2 {
        font-size: 40px;
    }

    .form-group label {
        font-size: 15px;
    }

    input::placeholder {
        font-size: 12px;
    }

    .left-panel {
        flex: 0 0 40%;
    }

    .heads h2 {
        font-size: 60px !important;
        font-weight: 500;
    }

    .img_first {
        height: 400px;
    }

    .cat_detail span {
        font-size: 20px;
    }

    .cat_detail p {
        padding-bottom: 2px;
    }

    .right-panel {
        flex: 0 0 60%;

    }

    .sec_img {
        width: 375px;

    }


    .first_img {
        top: 15%;
        left: 10%;
    }

    .para {
        padding-right: 0px;
        font-size: 20px;
    }

    .know_btn {
        padding: 10px 40px;
    }

    .img_sec {
        height: 530px;
    }
    .card_detail h4 {
        font-size: 24px !important;
    
    }
    
    .card_detail p {
        font-size: 14px !important;
    }
    
    .premia_text h3 {
        font-size: 100px;
        top : 10px
    }
    .premia_text .building-img {
        top: 26px !important;
    }
    .aminities_card {
        height: 170px !important ;
        width: 90%;
    }
}


@media (max-width: 768px) {
    .site_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 6% 0;
    }

    .site_right img {
        width: 100%;
        height: 600px;
    }

    .site_left {
        width: 100%;
    }

    .site_left h2 {
        font-size: 40px;
    }

    .form-group label {
        font-size: 15px;
    }

    input::placeholder {
        font-size: 12px;
    }

    .left-panel {
        flex: 0 0 40%;
    }

    .heads h2 {
        font-size: 60px !important;
        font-weight: 500;
    }

    .para {
        padding-right: 0px;
        font-size: 20px;
    }

    .content_first {
        flex-direction: column;
        text-align: center;
    }

    .cat_detail span {
        font-size: 20px;
    }

    .cat_detail p {
        padding-bottom: 2px;
    }

    .first_img {
        top: 15%;
        left: 5%;
        width: 250px;
    }

    .img_first {
        height: 350px;
    }

    .img_sec {
        height: 530px;
    }

    .sec_img {
        margin: auto;
        width: 400px;
    }

    .left-panel,
    .right-panel {
        flex: 1;
        width: 100%;
    }

    .para {
        padding-right: 0px;
    }
    .card_detail h4 {
        font-size: 20px !important;
    
    }
    
    .card_detail p {
        font-size: 12px !important;
    }

    .premia_text h3 {
        font-size: 57px;
        bottom: -15px;
        /* top : -25px */
    }
    .premia_text .building-img {
        top: 22px !important;
    }
    
    .img-scroll {
        display: unset !important;
        overflow-y: scroll;
        max-height: 600px;
    }
    .aminities_card {
        height: 122.39px !important ;
        width: 90%;
    }
    
}

@media (max-width: 425px) {
    .img_sec {
        height: 430px;
    }

    .site_right img {
        width: 100%;
        height: 400px;
    }

    .card_detail h4 {
        font-size: 18px !important;
    
    }
    
    .card_detail p {
        font-size: 10px !important;
    }

    .catalog {
        /* flex-direction: column; */
        margin: 15px;
        justify-content: center;
    }

    .first_img {
        top: 20%;
        left: 2%;
    }

    .sec_img {
        width: 330px;
    }

    .img_first {
        height: 270px;
    }

    .img-scroll {
        display: unset !important;
        overflow-y: scroll;
        max-height: 600px;
    }
    .aminities_card {
        height: 122.39px !important ;
        width: 90%;
    }

}

@media (max-width: 425px) and (min-width: 300px) {
    .aminities_card {
        height: 150px !important ;
        width:90%;
    }
}



#slider_testimonial .slick-initialized .slick-slide {
    border-radius: 10px;
    margin: 0 10px;
}

.slick-slider {
    /* height: 450px !important; */
}

.location_card {
    width: 100% !important;
    padding: 3% !important;
    justify-content : left !important;
    align-items : left !important
}

.img-scroll {
    display: flex;
    flex-direction: row !important;

}

